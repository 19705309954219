import axios from "axios";

export const fetchWebRingData = async (id) => {
    try {
        const response = await axios.get(`https://umaring.mkr.cx/${id}`);
        return response.data;
    }
    catch (error) {
        console.error("Error fetching data from the UMass WebRing API.", error);
        return null;
    }
};
import { TECH } from "content/tech-stack";

import KodexDraw from "../public/images/projects/kodex-draw.gif";
import AITicTacToe from "../public/images/projects/tic-tac-toe.gif";
import MapantserBlog from "../public/images/projects/mapantser-home-wide.png";
import PaintCar from "../public/images/projects/paint-car.gif";
import Recover from "../public/images/projects/recover.gif";
import MazeSolver from "../public/images/projects/maze.gif";
import Cog from "../public/images/projects/cogwheel.gif";

export const PROJECTS = [
  {
    name: "Kodex Draw",
    type: ["Web Apps", "Personal Projects"],
    tech: [
      TECH.javascript,
      TECH.html_5,
      TECH.css_3,
      TECH.sockets,
      TECH.paper_js,
      TECH.hammer_js
    ],
    url: "https://kodex-draw.netlify.app/",
    repository: "https://github.com/jeevesh2002/Kodex-Draw",
    description: `A Drawing canvas that uses web sockets for real-time bidirectional event-based communication that helps in collaboration. The app also smoothens whatever you draw giving you more control over the diagram which can be used on a Digital Board in Classrooms.`,
    featuredImage: KodexDraw,
  },
  {
    name: "AI Tic-Tac-Toe",
    type: ["Games", "HarvardX","Machine Learning"],
    tech: [
        TECH.python,
        TECH.pygame,
        TECH.minimax,
        TECH.alpha_beta_pruning,
    ],
    url: "https://github.com/jeevesh2002/Tic-Tac-Toe",
    repository: "https://github.com/jeevesh2002/Tic-Tac-Toe",
    description: "A Tic-Tac-Toe game that uses the minimax algorithm with alpha-beta pruning to make the AI unbeatable. The AI uses a heuristic function to evaluate the board and make the best move.",
    featuredImage: AITicTacToe,
},
{
  name: "mApantser-Blog",
  type: ["Blogs", "Personal Projects", "Web Apps"],
  tech: [
    TECH.flask,
    TECH.jinja,
    TECH.python,
    TECH.firebase,
    TECH.cloud_firestore,
    TECH.bootstrap,
    TECH.heroku,
    TECH.netlify,
    TECH.html,
    TECH.css,
    TECH.javascript,
  ],
  url: "https://mapantser.netlify.app/",
  description: "A Blogging website that uses Flask as the backend and Jinja as the templating engine. It uses Firebase for authentication and Cloud Firestore for storing the data. The app is hosted on Heroku and Netlify. The app facilitates creation and management of blog posts and tracks stats and analytics. Tags can also be added to each blog post to organize and find them later easily.",
  featuredImage: MapantserBlog,
},

{
  name: "Paint Car",
  type: ["Games", "Personal Projects", "Web Apps"],
  tech: [
    TECH.javascript,
    TECH.html_5,
    TECH.css_3,
    TECH.canvas,
    TECH.netlify
  ],
  url: "https://jeevesh-car-game.netlify.app/",
  description: `A simple paint car game made using vanilla js, html and css. It uses the canvas element to draw the car and the road. It also uses the requestAnimationFrame() method to animate the car and the road. It is hosted on Netlify.`,
  featuredImage: PaintCar,
},

{
  name: "Recover",
  type: ["HarvardX", "Cybersecurity"],
  tech: [
    TECH.c,
    TECH.jpeg,
    TECH.bmp,
    TECH.png,
  ],
  description: `A program that recovers JPEGs from a forensic image. It uses the C programming language and the properties of JPEGs to recover the images.`,
  url: "https://github.com/jeevesh2002/Recover",
  repository: "https://github.com/jeevesh2002/Recover",
  featuredImage: Recover,
},
{
  name: "Maze Solver",
  type: ["Personal Projects", "Machine Learning"],
  tech: [
    TECH.python,
    TECH.pygame,
    TECH.breadth_first_search,
    TECH.manhattan_distance,
    TECH.pillow
  ],
  description: `A program that solves mazes using the breadth first search algorithm. It uses the pillow and pygame library to draw the maze and the solution`,
  featuredImage: MazeSolver,
},
{
  name: "CTF",
  type: ["Personal Projects", "Cybersecurity"],
  tech: [
    TECH.reverse_engineering,
    TECH.side_channel_attacks,
    TECH.forensics,
    TECH.pwn,
    TECH.bash,
  ],
  description: `A work in progress CTF website that hosts question i created from scratch. Topics include reverse engineering, side-channel attacks, forensics, assembly, pwn, python, scripting, steganography, cryptography, misc, web, and more.`,
  url: "https://jeevesh2002.github.io/level1-hello-friend.mov",
  featuredImage: Cog,
},
{
  name: "Automated Attendance System",
  type: ["Personal Projects", "Machine Learning", "Web Apps"],
  tech: [
    TECH.python,
    TECH.opencv,
    TECH.keras,
    TECH.tensorflow,
    TECH.dlib,
    TECH.google_sheets_api,
    TECH.pillow,
  ],
  description: "A program that uses face recognition to mark attendance. It uses the face-recognition module which is built on top of OpenCV and dlib library to detect faces and encode the facial features. It uses the Google Sheets API to mark attendance in a google sheet.",
  featuredImage: Cog,
},
{
  name: "Portfolio V3",
  type: ["Personal Projects", "Web Apps", "Portfolio"],
  tech: [
    TECH.react,
    TECH.nodejs,
    TECH.express,
    TECH.firebase
  ],
  url: "https://portfoliov3.jeeveshkrishna.com/",
  description: `My third iteration of my website. This website is built using React for the frontend and Node.js with Express for the backend.`,
},
{
  name: "Portfolio V2",
  type: ["Personal Projects", "Web Apps", "Portfolio"],
  tech: [
    TECH.html_5,
    TECH.css_3,
  ],
  url: "https://portfoliov2.jeeveshkrishna.com/",
  description: `My second iteration of my website. This website is built using only html and css no javascript!!!, yet it is responsive and looks good on all devices.`,
},
{
  name: "Portfolio V1",
  type: ["Personal Projects", "Web Apps", "Portfolio"],
  tech: [
    TECH.html_5,
    TECH.css_3,
    TECH.javascript,
    TECH.bootstrap
  ],
  url: "https://portfoliov1.jeeveshkrishna.com/",
  description: `My first portfolio website. It is a simple static website that uses HTML, CSS, and JavaScript.`,
},
{
  name: "Tic-Tac-Toe over LAN",
  type: ["Games", "Personal Projects"],
  tech: [
    TECH.java,
    TECH.swing,
    TECH.sockets,
    TECH.socket_programming
  ],
  repository: "https://github.com/jeevesh2002/Tic-Tac-Toe-over-LAN--java",
  description: "Multiplayer Tic-Tac-Toe game that can be played over LAN. It uses the Java Swing library to create the GUI and the Java Socket Programming to create the server and the client."
},
{
  name: "Wordle Solver CLI",
  type: ["Personal Projects", "CLI"],
  tech: [
    TECH.python,
    TECH.bash
  ],
  description: "A CLI tool that solves the famous wordle game. It is written in python and uses the Global Regular Expression Print (GREP) command to find the words and solve the puzzle.",
  repository: "https://github.com/jeevesh2002/wordle"
},
{
  name: "LAN-Messenger",
  type: ["Personal Projects", "CLI"],
  tech: [
    TECH.python,
    TECH.sockets,
    TECH.networking,
    TECH.socket_programming,
  ],
  description: "A command line clone of the famous OMEGLE website. It connects you to a random person the same network and you can chat with them. It uses sockets for creating a connection between the two users.",
  repository: "https://github.com/jeevesh2002/LAN-Messaging"
},
{
  name: "Jigsaw-Web",
  type: ["Personal Projects", "Web Apps", "Cybersecurity"],
  tech: [
    TECH.python,
    TECH.opencv,
    TECH.numpy,
    TECH.flask,
    TECH.threading,
    TECH.heroku
  ],
  description: "A flask web app that allows you to create jigsaw puzzles from images. It uses the opencv library to create the jigsaw puzzle. It is hosted on Heroku.",
  repository: "https://github.com/jeevesh2002/Jigsaw-Web"

},
{
  name: "Dank Memer Hack",
  type: ["Personal Projects", "CLI"],
  tech: [
    TECH.python,
    TECH.pynput
  ],
  description: "A hack wriiten in python that automatically farms coins and other valubles on the famous discord bot Dank Memer. It uses the pynput library to automate the key presses.",
  repository: "https://github.com/jeevesh2002/Dank"
},
{
  name: "eNcRyPtOr",
  type: ["Personal Projects", "Cybersecurity"],
  tech: [
    TECH.python,
  ],
  description: "A command line tools that encrypts and decrypts files/folders. It uses a very basic shift cipher to encrypt and decrypt the files. Please note that this is not a secure encryption method and should not be used to encrypt sensitive data. Please use it at your own risk, i am not responsible for any damage caused by this program.",
  repository: "https://github.com/jeevesh2002/encrypt"
}
];

import React, { useEffect, useState } from 'react';
import { fetchWebRingData } from '../utils/umassapi';
import ArrowLeftIcon from '@heroicons/react/24/solid/ArrowLeftIcon';
import ArrowRightIcon from '@heroicons/react/24/solid/ArrowRightIcon';
import umassImage from '../public/images/umass.png';
import ReactTooltip from 'react-tooltip';


const WebRing = ({ userId }) => {
  const [webRingData, setWebRingData] = useState(null);

  useEffect(() => {
    const getData = async () => {
      const data = await fetchWebRingData(userId);
      setWebRingData(data);
    };
    getData();
  }, [userId]);

  if (!webRingData) return null;

  return (
    <div className="group flex flex-col items-center justify-center bg-clip-padding text-sm font-medium text-gray-600 transition-all" data-tip="This is a web ring, click next or prev arrow to navigate">
      <img src={umassImage.src} alt="UMass Amherst Web Ring" className="h-8 w-auto" style={{ imageRendering: 'pixelated' }}/>
      <div className="flex items-center gap-2 mt-2">
        <a href={webRingData.prev.url} className="w-7" title={`Previous: ${webRingData.prev.name}`} target="_blank" rel="noopener noreferrer">
          <ArrowLeftIcon className="h-10 w-8 text-gray-600 group-hover:text-gray-800 d:text-gray-300 d:group-hover:text-gray-400" />
        </a>
        <span className="text-aqua-600">
            UMass Web Ring
        </span>
        <a href={webRingData.next.url} className="w-7" title={`Next: ${webRingData.next.name}`} target="_blank" rel="noopener noreferrer"
        >
          <ArrowRightIcon className="h-10 w-8 text-gray-600 group-hover:text-gray-800 d:text-gray-300 d:group-hover:text-gray-400" />
        </a>
      </div>
    </div>
  );
};

export default WebRing;

// @ts-ignore
import heroCode from "!!raw-loader!content/code-blocks/break_space_time_continuum.f90"; // Adding `!!` to a request will disable all loaders specified in the configuration
import { SiNodeDotJs } from "@react-icons/all-files/si/SiNodeDotJs";
import { SiReact } from "@react-icons/all-files/si/SiReact";
import { SiShopify } from "@react-icons/all-files/si/SiShopify";
import { SiTailwindcss } from "@react-icons/all-files/si/SiTailwindcss";
import { Link } from "components/link";
import { TECH } from "content/tech-stack";
import party from "party-js";

export const HERO = {
  pre: "Welcome to my site.",
  heading: (
    <>
      I'm <strong>Jeevesh Krishna</strong>, a Software Developer.
    </>
  ),
  tech: [
    // TECH.nextjs,
    // {
    //   name: "Node.js",
    //   Icon: ({ className }) => <SiNodeDotJs className={className} />,
    // },
    // {
    //   name: "Tailwind",
    //   Icon: ({ className }) => <SiTailwindcss className={className} />,
    // },
    // {
    //   name: "Shopify",
    //   Icon: ({ className }) => <SiShopify className={className} />,
    // },
  ] as const,
  body: (
    <>
      With an insatiable love for crafting innovative solutions. My journey in the world of development has taken me through various domains, from building dynamic web applications to mastering the intricacies of system administration, cybersecurity, computer networks, operating systems, databases, cloud computing, and even delving into the fascinating realm of quantum computing.
      <br /><br />
      Yet, it's the thrill of problem-solving and the joy of creating that truly fuel my passion. Whether it's optimizing code for performance, securing networks, or architecting intuitive user interfaces, I thrive on the challenges that come with pushing boundaries and exploring new technologies.
      {/* that makes you feel{" "} */}
      {/* <em
        className="relative cursor-pointer before:absolute b:bottom-0 b:-z-10 b:h-3 b:w-full b:-rotate-2 b:animate-hint-hint b:bg-pink-400/70 b:blur-sm d:b:bg-pink-600"
        onClick={(e) => {
          e.currentTarget.classList.remove("before:absolute");
          party.confetti(e.currentTarget, { count: 40 });
        }}
      >
        AWE!
      </em> */}
      .<span className="mt-4 block" />Currently, I'm immersed in research, delving into the fascinating possibilities of integrating modern cryptographic techniques into machine learning models. This endeavor is driven by my love for building zero-trust and secure-by-design systems while uncovering fresh avenues in the domain of privacy-preserving machine learning.
      {/* .<span className="mt-4 block" />I am always keen to learn and explore new technologies,
      frameworks and programming languages. Currently, I'm learning about{" "}
      <Link target="_blank" href="https://astro.build/" className="underline hfa:text-sky-500">
        Astro
      </Link>{" "}
      and{" "}
      <Link href="https://replicache.dev" target="_blank" className="underline hfa:text-sky-500">
        Replicache
      </Link>
      . */}
    </>
  ),
  cta1: {
    href: "mailto:hello@jeeveshkrishna.com",
    name: "Let's Work",
  },
  cta2: {
    href: "/resume",
    name: "Resume",
  },
  code: `${heroCode}`,
};

// @ts-ignore
import HikingProfile from "../public/images/about/profile-lionshead.jpg";
import party from "party-js";

import axios from "axios";

export const ABOUT = {
  stats: [
    {
      statistic: `${
        new Date(Date.now() - new Date("2002-11-10T01:30:00").getTime()).getFullYear() - 1970
      }`,
      tooltip: `${Math.round(
        (Date.now() - new Date("2002-11-10T01:30:00").getTime()) / 1000
      )} seconds`,
      caption: "Years Old",
    },
    {
      statistic: `${new Date().getFullYear() - 2018}` + "+",
      tooltip: "First line of code written in 2018",
      caption: "Years Dev",
    },
    {
      statistic: "NaN",
      tooltip: "Not a Number (NaN) - From Parsing Invalid Strings",
      caption: "Days Since Last Syntax Error",
    },
    {
      statistic: "∞",
      tooltip: "Infinity",
      caption: "Cups of Coffee Consumed",
    },
  ],
  description: (
    <>
      <p>
      I'm currently residing in Massachusetts where I am pursuing my Master of Science in Computer Science at UMass Amherst. Tech has been my lifelong passion – from my undergrad days studying Computer Science Engineering to my current focus on the fascinating intersection of machine learning and cryptography. 
      </p>
      <p>
      As a teenager, I was the classic computer nerd, spending countless hours tinkering with hardware and software, breaking things just to figure out how to fix them again. That obsession fueled my curiosity and laid the foundation for where I am today – an unapologetic tech geek who finds immense joy in exploring the boundless realms of technology. Whether diving deep into machine learning algorithms, cracking cryptographic codes, or weaving elegant lines of code, I'm in my element.
      </p>
      <br />
      <em
        className="relative cursor-pointer before:absolute b:bottom-0 b:-z-10 b:h-3 b:w-full b:-rotate-2 b:animate-hint-hint b:bg-pink-400/70 b:blur-sm d:b:bg-pink-600"
        onClick={(e) => {
          e.currentTarget.classList.remove("before:absolute");
          party.confetti(e.currentTarget, { count: 40 });
        }}
      >
        Escaping the matrix! - One line of code at a time. 👨‍💻
      </em> 
    </>
  ),
  images: [
    {
      src: "",
      alt: "",
    },
  ],
};

